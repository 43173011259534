import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'
import AboutUs from '../components/AboutUs'
import ContactUs from '../components/ContactUs'

const AboutUsPage = () => {
  return (
    <LayoutRoot isWhiteCredential>
      <>
        <AboutUs isDetail/>
        <div style={{marginTop: 50}} />
        <ContactUs />
      </>
    </LayoutRoot>
  )
}
export default AboutUsPage
